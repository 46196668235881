import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/index.jsx";
import SaltPassord from "../../services/md5.js";
import api from "../../services/api.js";
import {useEffect} from 'react';

function Senha(){

    const [senha, setSenha] = useState("");
    const [senha2, setSenha2] = useState("");
    const [sucesso, setSucesso] = useState("");
    const [tipo, setTipo] = useState(0);
    const navigate = useNavigate();


    function SalvarDados(){
        setSucesso("");

        if (senha != senha2){
            alert("As senha não conferem. Digite novamente");
            return;
        }

        api.put('v1/usuarios/senha/'+ localStorage.getItem('sessionEmail') , {
            senha: SaltPassord(senha),
            senha2: SaltPassord(senha2)
        }).then(retorno =>{
            if (retorno.response){
                if (retorno.response.status != "200"){
                    alert(retorno.response.data.erro);
                }
            } else {
                setSucesso("S");

                api.post('v1/auditoria/'  , {
                    vfuncionario: localStorage.getItem('sessionId'),
                    vdata: '',
                    vhora: '08:08',
                    vtabela: 'web',
                    voperacao: 'Troca de senha web',
                    videntificador: 'Troca de web ' + localStorage.getItem('sessionId')
                }) 

                if (localStorage.getItem('sessionEmail') == 0) {
                    navigate('/requisicao/pacientes/')}
                else
                {
                navigate('/requisicao/medicos/')
                }
            }

        }).catch(err =>{            
            setSucesso('N');
        });
    }

    return <div className="container-fluid mt-page cardapio">
    <Navbar />
  
    <div className="row col-lg-6 offset-lg-3">
        
        <div className="col-12 mt-4">
            <h2 className="mt-2">Alterar Senha</h2>            
        </div>

        <div className="col-12 mt-4">
            <div>
                <div className="mb-4">
                    <label htmlFor="InputNome" className="form-label">Informe a nova senha</label>
                    <input type="password" onChange={(e) => setSenha(e.target.value)} className="form-control" id="InputNome" aria-describedby="nome" />                    
                </div>

                <div className="mb-5">
                    <label htmlFor="InputEmail" className="form-label">Confirma a nova senha</label>
                    <input type="password" onChange={(e) => setSenha2(e.target.value)} className="form-control" id="InputEmail" aria-describedby="email" />                    
                </div>

                {sucesso == "S" ? <div className="alert alert-success mt-2" role="alert">Senha alterada com sucesso.</div> : null}

                <div className="d-flex justify-content-end">                    
                    <button  type="button" className="btn btn-danger mt-3" onClick={SalvarDados}>Alterar Senha</button>
                </div>

              
            </div>
        </div>

    </div>

</div>
}

export default Senha;