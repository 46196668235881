import axios from 'axios';

const api = axios.create({
   baseURL: 'https://api.sap.net.br:9070/api'   //   <<---- MUDE AQUI PARA A SUA API... 
   //baseURL: 'http://localhost:9070'   //   <<---- MUDE AQUI PARA A SUA API...
});

api.interceptors.request.use(req => {
    if (localStorage.getItem('sessionToken')) {
        req.headers.Authorization = `Bearer ${localStorage.getItem('sessionToken')}`;
    }

    return req;
},
(err) => {
    console.log(err);
});


export default api;