import { Navigate , BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from "./pages/login/index.jsx";
import Pacientes from "./pages/requisicao/pacientes/pacientes"
import Medicos from "./pages/requisicao/medicos/medicos"
import Perfil from "./pages/perfil/index.jsx";
import Senha from "./pages/senha/index.jsx";
import PrivateRoute from "./components/private-route";


function Rotas(){
    return <>
        
        <BrowserRouter>
            <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/" element={<Login />} />
                <Route path="/perfil" element={<PrivateRoute><Perfil/></PrivateRoute>} />
                <Route path="/senha" element={<PrivateRoute><Senha/></PrivateRoute>} />
                <Route exact path="/requisicao/pacientes" element={<PrivateRoute><Pacientes /></PrivateRoute>} />
                <Route exact path="/requisicao/medicos" element={<PrivateRoute><Medicos /></PrivateRoute>} />
            </Routes>
        </BrowserRouter>
    </>
}

export default Rotas;