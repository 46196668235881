import { useEffect, useState } from "react";
import Requisicao from "../../../components/requisicao/paciente/lista/index.jsx";
import api from '../../../services/api';
import Navbar from "../../../components/navbar/index.jsx";
import {StatusRequisicao} from "../../../services/functions.js";
import RequisicaoModal from "../../../components/requisicao/paciente/modal/index.jsx";
import "./pacientes.css";

function Pacientes(){

    const [requisicoes, setRequisicoes] = useState([]);
    const [isRequisicaoOpen, setIsRequisicaoOpen] = useState(false);
    const [dados_categoria, setDados_Categoria] = useState({requisicao: 0, pdf64: ""});
    const [status, setStatus] = useState("");

    function ListarRequisicoes(){
        api.get('/v1/requisicoes')
        .then(response => setRequisicoes(response.data))
        .catch(err => console.log(err));
    }

    function openModalRequisicao(requisicao){

        console.log(requisicao);

        if (requisicao > 0) {
            api.get(`v1/requisicoes/${requisicao}`)
            .then(response => {
                setDados_Categoria(response.data);
                setIsRequisicaoOpen(true);    
                
                api.post('v1/auditoria/'  , {
                    vfuncionario: localStorage.getItem('sessionId'),
                    vdata: '',
                    vhora: '08:08',
                    vtabela: 'web',
                    voperacao: 'Impressão Laudo web',
                    videntificador: 'Impressão Laudo web ' + requisicao
                })           

            })
            .catch(err => console.log(err));
        } else {
            setDados_Categoria([]);
            setIsRequisicaoOpen(true);
        }
        
    }

    function closeModalRequisicao(){
        setIsRequisicaoOpen(false);
        ListarRequisicoes();
    }
    
    useEffect(() => ListarRequisicoes(), []);

    return <div className="container-fluid mt-page">

        <Navbar />     
       
        <RequisicaoModal isOpen={isRequisicaoOpen} 
                       dados_categoria={dados_categoria}        
                       onRequestClose={closeModalRequisicao}
                        />

        <div className="container-fluid mt-page form-requisicao">

            <div className="ms-4 d-flex justify-content-between">
                <div>
                     <div className="form-control d-inline me-3">
                        <select name="status" id="status" onChange={(e) => setStatus(e.target.value)}>
                            <option value="">Todos os Status</option>
                            <option value="A">Aberto</option>
                            <option value="F">Finalizado</option>
                        </select>
                    </div>
                    <button className="btn btn-primary" onClick={ListarRequisicoes}>Filtrar</button>
                </div>
                
            </div> 

            <div className="mt-5 ms-4 me-4">
                <table className="table">
                    <thead>
                        <tr>
                        <th scope="col">Requisição</th>
                        <th scope="col">Data Exame</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            requisicoes.map((requisicao) => {
                            return <Requisicao key={requisicao.requisicao}
                                         requisicao={requisicao.requisicao}   
                                         dataexame={requisicao.dtevol}
                                         tipo={requisicao.biocitot}
                                         status={StatusRequisicao(requisicao.status)}
                                         onClickGeraPDF={openModalRequisicao}
                                          />
                            })                            
                        }                        
                        
                    </tbody>
                </table>
            </div>

        </div>                    

    </div>
}

export default Pacientes;