import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from '../../assets/logo-pb.png';
import Fundo from '../../assets/fundo-login.jpg';
import { Link } from "react-router-dom";
import './style.css';
import api from '../../services/api';
import SaltPassword from "../../services/md5";


function Login(){

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [tipo, setTipo] = useState(0);

    const [vfuncionario, setFuncionario] = useState(email);
    const [vdata, setData] = useState('');
    const [vhora, setHora] = useState('');
    const [vtabela, setTabela] = useState('');
    const [voperacao, setOperacao] = useState('');
    const [videntificador, setIdentificador] = useState('');

    const [sucesso, setSucesso] = useState('');
    const [loading, setLoading] = useState(false);

    function ProcessaLogin(e){
        e.preventDefault();

        setSucesso('');
        setLoading(true);
        
        api.post('v1/usuarios/login/' + tipo , {
            email: email,
            senha: SaltPassword(senha)
        })
        .then(response => {   
                if (tipo == 0) {
                    localStorage.setItem('sessionToken', response.data.token);
                    localStorage.setItem('sessionId', response.data.codpaci);
                    localStorage.setItem('sessionEmail', 0);
                    localStorage.setItem('sessionNomePaciente', response.data.nomepaci);
                    localStorage.setItem('sessionTipo', 0);
                    setSucesso('S');

                    api.post('v1/auditoria/'  , {
                        vfuncionario: email,
                        vdata: '',
                        vhora: '08:08',
                        vtabela: 'web',
                        voperacao: 'login paciente web',
                        videntificador: 'login paciente web ' + email
                    })

                    navigate('/requisicao/pacientes/')}
                else
                {
                    localStorage.setItem('sessionToken', response.data.token);
                    localStorage.setItem('sessionId', response.data.crm);
                    localStorage.setItem('sessionEmail', 1);
                    localStorage.setItem('sessionNomePaciente', response.data.medico);
                    localStorage.setItem('sessionTipo', 1);

                    api.post('v1/auditoria/'  , {
                        vfuncionario: email,
                        vdata: '',
                        vhora: '08:08',
                        vtabela: 'web',
                        voperacao: 'login paciente web',
                        videntificador: 'login paciente web ' + email
                    })

                    setSucesso('S');
                navigate('/requisicao/medicos/')
                }
        })
        .catch(err => {
            setSucesso('N');
            setLoading(false);
        })
    }

    return <div className="row">
        <div className="col-sm-6 d-flex justify-content-center align-items-center text-center">
            <form className="form-login mt-5">

                <h3 className="mb-4">SAP</h3>
                <h6 className="mb-3">Acesse seu resultado</h6>

                <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" onChange={(e) => setTipo(e.target.value)}  name="inlineRadioOptions" id="inlineRadio1" value="0" />
                <label className="form-check-label" for="inlineRadio1">Paciente</label>
                </div>
                <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" onChange={(e) => setTipo(e.target.value)}  name="inlineRadioOptions" id="inlineRadio2" value="1" />
                <label className="form-check-label" for="inlineRadio2">Médico</label>
                </div>

                <div className="form-floating">
                   <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" id="floatingInput" placeholder="Código" />
                    <label htmlFor="floatingInput">Código</label>
                </div>

                <div className="form-floating">
                    <input type="password" onChange={(e) => setSenha(e.target.value)} className="form-control" id="floatingInput" placeholder="Senha" />
                    <label htmlFor="floatingInput">Senha</label>
                </div>

                <button onClick={ProcessaLogin} className="w-100 btn btn-lg btn-danger" disabled={loading}>
                    {loading ? <div>
                                <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                                <span className="ms-2">Enviando...</span>
                                </div> : <span className="ms-2">Acessar</span>
                    }
                </button>

                {sucesso === 'N' ? <div className="alert alert-danger mt-2" role="alert">E-mail ou senha inválida</div> : null}

                <div className="mt-5">
                    <Link to="/cadastro">Esqueci minha senha!</Link>
                </div>

                <img src={Logo} alt="Delivery Mais" className="mt-5"/>
            </form>    
        </div>

        <div className="col-sm-6 px-0 d-none d-sm-block">
            <img className="background-login" src={Fundo} alt="Delivery Mais" />
        </div>
        
    </div>
}

export default Login;