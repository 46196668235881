import { Link } from "react-router-dom";

function Requisicao(props){

    const data_exame = new Date(props.dataexame.substring(0, 19)); // 2023-03-09T19:45:54.209Z

    return <tr>
        <td>{props.requisicao}</td>
        <td>{props.nomepaci}</td>
        <td>{new Intl.DateTimeFormat('pt-BR', {dateStyle: 'short'}).format(data_exame)}</td>
        <td>{props.tipo}</td>
        <td>{props.status}</td>
        <td>
            <div className="dropdown">
            { props.onClickGeraPDF ?
            <button onClick={(e) => props.onClickGeraPDF(props.requisicao)} className="btn btn-outline-danger me-3 m-2">Imprimir</button> : null
            }
            </div>
        </td>
    </tr>
}

export default Requisicao;