import React, {useEffect, useState} from 'react';
import Modal from "react-modal/lib/components/Modal";
import closeIcone from '../../../../assets/close.png';
import './style.css';
import { Viewer } from '@react-pdf-viewer/core';
import { printPlugin, RenderPrintProps } from '@react-pdf-viewer/print';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
  }/pdf.worker.js`;

Modal.setAppElement('#root');

function RequisicaoModal(props){
    const [requisicao, setRequisicao] = useState(0);
    const [pdf64, setPdf64] = useState("");
    
    useEffect(() => {
        if (props.dados_categoria)  {
            setRequisicao(props.dados_categoria.requisicao)
            setPdf64(props.dados_categoria.pdf64)
        }
          
    }, [props.isOpen]);   

    const base64toBlob = (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
    
        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);
    
        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }
    
        return new Blob([out], { type: 'application/pdf' });
    };

    const blob = base64toBlob(pdf64);
    const url = URL.createObjectURL(blob);

    const printPluginInstance = printPlugin();
    const { Print } = printPluginInstance;

    <Print>
    {(props) => (
        <button
            style={{
                backgroundColor: '#357edd',
                border: 'none',
                borderRadius: '4px',
                color: '#ffffff',
                cursor: 'pointer',
                padding: '8px',
            }}
            onClick={props.onClick}
        >
            Print
        </button>
    )}
    </Print>


     return <Modal isOpen={props.isOpen}
                  onRequestClose={props.onRequestClose}
           
                  overlayClassName="react-modal-overlay"
                  className="react-modal-content">
            
            <button type="button" onClick={props.onRequestClose} className="react-modal-close">
                <img src={closeIcone} alt="Fechar" />
            </button>

            <div
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    padding: '4px',
                }}
            >
                <Print>
                    {(props) => (
                        <button
                            style={{
                                backgroundColor: '#357edd',
                                border: 'none',
                                borderRadius: '4px',
                                color: '#ffffff',
                                cursor: 'pointer',
                                padding: '8px',
                            }}
                            onClick={props.onClick}
                        >
                            Imprimir
                        </button>
                    )}
                </Print>
            </div>
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                }}
            >
                <Viewer fileUrl={url} plugins={[printPluginInstance]} />
            </div>
        </div>

    </Modal>
}

export default RequisicaoModal;