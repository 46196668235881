import React , { useEffect, useState, useRef } from "react";
import Requisicao from "../../../components/requisicao/medico/lista/index.jsx";
import api from '../../../services/api';
import Navbar from "../../../components/navbar/index.jsx";
import {StatusRequisicao} from "../../../services/functions.js";
import RequisicaoModal from "../../../components/requisicao/medico/modal/index.jsx";
import "./medicos.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable  , ptBR  } from "primereact/datatable" ;
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar"
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';

         

function Pacientes(){

    const [requisicoes, setRequisicoes] = useState([]);
    const [isRequisicaoOpen, setIsRequisicaoOpen] = useState(false);
    const [dados_categoria, setDados_Categoria] = useState({requisicao: 0, pdf64: ""});
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);

    const getSeverity = (status) => {
        switch (status) {
            case 'Aguardando':
                return 'danger';

            case 'Finalizado':
                return 'success';
        }
    };


    const actionBodyTemplate = (rowData) => {
        if (rowData.status == "F")
        {
        return (
            <React.Fragment>
                <Button icon="pi pi-print" rounded outlined className="mr-2" onClick={() => openModalRequisicao(rowData.requisicao)} disabled={loading} />
            </React.Fragment>
        );}
        else
        {
            return (
                <React.Fragment>
                    <Button icon="pi pi-print" rounded outlined className="mr-2" onClick={() => openModalRequisicao(rowData.requisicao)} disabled />
                </React.Fragment>
            );}      
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };
    const DataExameBodyTemplate = (rowData) => {
        const data_exame = new Date(rowData.dtevol.substring(0, 19)); 
        return ( formatDate(data_exame) );
    };

    const StatusBodyTemplate = (rowData) => {
        return <Tag value={StatusRequisicao(rowData.status)} severity={getSeverity(StatusRequisicao(rowData.status))} />;
    };
    
    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yyyy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };


    function ListarRequisicoes(){
        api.get('/v1/requisicoes/medicos')
        .then(response => setRequisicoes(response.data))
        .catch(err => console.log(err));
    }

    function openModalRequisicao(requisicao){

        if (requisicao > 0) {
            api.get(`v1/requisicoes/${requisicao}`)
            .then(response => {

                setDados_Categoria(response.data);
                setLoading(true);
                setIsRequisicaoOpen(true);   

                api.post('v1/auditoria/'  , {
                    vfuncionario: localStorage.getItem('sessionId'),
                    vdata: '',
                    vhora: '08:08',
                    vtabela: 'web',
                    voperacao: 'Impressão Laudo web',
                    videntificador: 'Impressão Laudo web ' + requisicao
                }) 
                      
            })
            .catch(err => console.log(err));
        } else {
            setDados_Categoria([]);
            setIsRequisicaoOpen(true);
        }
        
    }

    function closeModalRequisicao(){
        setIsRequisicaoOpen(false);
        setLoading(false);
        ListarRequisicoes();
    }

    
    useEffect(() => ListarRequisicoes(), []);

    return <div className="container-fluid mt-page">

        <Navbar />     
       
        <RequisicaoModal isOpen={isRequisicaoOpen} 
                       dados_categoria={dados_categoria}        
                       onRequestClose={closeModalRequisicao}
                        />

        <div className="container-fluid mt-page form-requisicao">
        <div className="table-wrapper">
            <DataTable  locale="pt_BR" value={requisicoes} responsiveLayout="scroll" size="small" removableSort paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ Width: '100%' }   }>
            <Column field="nomepaci" header="Nome Paciente" sortable style={{ width: '15%' }} >  </Column>
            <Column field="dtevol" Column header="Data Exame" filterField="dtevol" dataType="date"  style={{ width: '10%' }}  body={DataExameBodyTemplate} filter filterElement={dateFilterTemplate}   />	
            <Column field="biocitot" header="Tipo"  style={{ width: '15%' }} ></Column>
            <Column field="status" header="Status" filterMenuStyle={{ width: '1rem' }}   style={{ width: '10%' }}   body={StatusBodyTemplate} />
            <Column async  body={actionBodyTemplate} exportable={false} style={{ width: '10%' }}> </Column>
            </DataTable>      
            </div>
            </div>  
        </div>                    
}

export default Pacientes;